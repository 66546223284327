<script lang="ts" setup>
import useModalsStore from '~/stores/modals'

const i18n = useI18n()

const modalsStore = useModalsStore()

const links = computed(() => [
  { to: '/', text: i18n.t('nav.home') },
  { to: '/about', text: i18n.t('nav.about') },
  { to: '/projects', text: i18n.t('nav.projects') },
  { to: '/contacts', text: i18n.t('nav.contacts') },
])
</script>

<template>
  <div
    class="mobile-nav"
    :class="{ active: modalsStore.active.includes('mobile-nav') }"
    @click.self="modalsStore.close('mobile-nav')"
  >
    <div class="mobile-nav__content">
      <div class="mobile-nav__links">
        <NuxtLinkLocale
          v-for="link in links"
          :key="link.to"
          :to="link.to"
          class="mobile-nav__link"
          @click="modalsStore.close('mobile-nav')"
        >
          {{ link.text }}
        </NuxtLinkLocale>
      </div>
      <div class="mobile-nav__actions">
        <button
          class="btn btn--primary btn--large btn--medium mobile-nav__call-button"
          @click.prevent="modalsStore.openContactModal({ source: 'call_back' })"
        >
          {{ $t('requestConsultation') }}
        </button>
      </div>
      <div class="mobile-nav__language-toggler">
        <LanguageToggler show-selected-name dropdown-direction="up" match-trigger-width />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/components/mobile-nav';
</style>
